input[type="text"] {
  margin-bottom: 0px !important;
}
.modal-content {
  padding: 20px;
  padding-bottom: 0px;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

#legal {
  font-family: "Times New Roman", Times, serif;
  border: 2px inset #999;
  padding: 20px;
  line-height: 15px;
  overflow-y: scroll;
}

.modal-dialog {
  max-width: 800px;
  min-height: calc(100% - 15.5rem);
}
/*
@media (min-width: 768px) {
  .modal-dialog-centered {
      width: 100%;
      height: 100%;
      min-height: 100%;
      margin: 0;
      padding: 0;
   }
} */

#plus {
  margin-right: 0px;
}

/* @media (min-width: 992px) {
  .modal-lg {
      width: auto;
  }
}

@media (min-width: 768px) {
.modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
 }
}

@media (min-width: 768px) {
  .modal-content {
      box-shadow: 0 5px 15px rgba(0,0,0,.5);
      height: auto;
      min-height: 100%;
      border-radius: 0;
  }
} */
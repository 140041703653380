span.navitem {
  font-size: 16px;
  padding: 20px 10px;
  display: inline-block;
  white-space: nowrap;
  color: #FFF;
  font-weight: 400;
  font-family: var(--ltn__body-font);
  cursor: pointer;
}

span.navitem:hover {
  color: #227CFF;
}

header {
  background: #122549;
  color: #FFF;
  border-bottom: 1px solid #5E6B83;
}

header a {
  font-size:16px !important;
  font-weight: 400 !important;
  color: #FFF !important;
}

header a:hover {
  color: #227CFF !important;
}

header li {
  padding-right: 30px;
}

header .container {
  max-width: 1400px !important;
}

@media (min-width: 1200px) and (max-width: 1300px) {
  span.navitem {
      font-size: 15px;
  }
}
#team p {
  font-size: 24px !important;
}

#team span {
  font-size: 16px !important;
}

#team img {
  cursor: pointer;
}

#team .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #122549;
}

#team .image {
  display: block;
  width: 100%;
  height: auto;
}

#team p.text {
  color: #FFF;
  position: absolute;
  bottom: 0px;
  font-size: 18px;
  padding: 20px;
}

.christo, .ray, .charlie, .peter {
  position: relative;
  cursor: pointer;
}

.christo:hover .overlay, .ray:hover .overlay, .charlie:hover .overlay, .peter:hover .overlay {
  opacity: 0.7 !important;
}